import axios from "axios";

const GRAPH_URL =
  "https://graph.microsoft.com/v1.0/me?$select=id,displayName,mail,department,manager,jobTitle";
const DATAVERSE_URL = "https://itpl-get-token.azurewebsites.net/api/AzureAPI";

export const loginService = {
  getUserDetails: (token) => {
    return axios.get(GRAPH_URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getDataverseToken: (reqBody) => {
    return axios.post(DATAVERSE_URL, reqBody, {
      headers: {
        Accept: "application/json",
      },
    });
  },
};
