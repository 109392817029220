import axios from "axios";
import Cookies from "js-cookie";
import { loginService } from "../loginService";

// /**
//  * Function to retrieve an access token.
//  * @returns {Promise<string>} The access token.
//  */

const tenant_id = "924d9ad1-25b5-46e1-beb4-612c790f213b";

// export const getToken = async () => {
//   const url =
//     "https://login.microsoftonline.com/" + tenant_id + "/oauth2/token";
//   const formData = new FormData();

//   const data = new URLSearchParams()
//   data.append("grant_type", "client_credentials");
//   data.append("client_id", "543a5943-d9ee-41f6-902d-c19555f9ab28");
//   data.append("client_secret", "ymc8Q~YHWByNn.ZmXh.NBfakbs-yNlgyuYYLzb78");
//   data.append(
//     "resource",
//     "https://agora-recruitment.crm8.dynamics.com"
//   );

//   // formData.append("tenant_id", "924d9ad1-25b5-46e1-beb4-612c790f213b");

//   try {
//     const response = await axios.post(url, data, {
//       headers: {
//         "Content-Type": "application/x-www-form-urlencoded",
//         'Accept': 'application/json',
//       },
//     });

//     return response.data.access_token; // Return the access token
//   } catch (error) {
//     console.error("Error fetching the token:", error);
//     console.error('Error:', error.response ? error.response.data : error.message);
//     throw new Error("Failed to retrieve access token");
//   }
// };

const url = "https://itpl-get-token.azurewebsites.net/api/azureapi";
const DEV_URL = "https://agora-recruitment.crm8.dynamics.com";
const PROD_URL = "https://talenthub.crm8.dynamics.com";

const currentOrigin = window.location.origin;
const RESOURCE_URL =
  currentOrigin === "https://talenthub.intelegain.com" ? PROD_URL : DEV_URL;

export const getToken = async (navigate) => {
  const payload = {
    grant_type: "client_credentials",
    client_id: "543a5943-d9ee-41f6-902d-c19555f9ab28",
    client_secret: "ymc8Q~YHWByNn.ZmXh.NBfakbs-yNlgyuYYLzb78",
    resource: RESOURCE_URL,
    tenant_id: "924d9ad1-25b5-46e1-beb4-612c790f213b",
  };
  return await loginService
    .getDataverseToken(payload)
    .then((response) => {
      if (response.status === 200) {
        Cookies.set("token", response.data.access_token, { expires: 1 });
        navigate("/candidates");
        return true;
      }
    })
    .catch((error) => {
      console.error("Error:", error.response?.data || error.message);
    });
};
