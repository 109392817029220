import React, { useState } from "react";
import "./loginPage.scss";
import LoginIcon from "../../images/login-icon.svg";
import ThreeDotLoading from "../../common-component/threedot-loading/ThreeDotLoading";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../service-api/axios-service/getToken";
import AgoraLogo from "../../images/AgoraLogo";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../utils/msalConfig";
import { loginService } from "../../service-api/loginService";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const SsoLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { instance } = useMsal();

  const handleLogin = () => {
    setIsLoading(true); // Start the loader
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        const accessToken = response.accessToken;
        fetchUserDetails(accessToken);
        // Call getToken function here
      })
      .catch((e) => {
        console.error("Login failed:", e);
        toast.error(e);
      })
      .finally(() => {
        setIsLoading(false); // Stop the loader
      });
  };

  const fetchUserDetails = (accessToken) => {
    const currentOrigin = window.location.origin;

    // Define allowed departments based on the origin
    const allowedDepartments =
      currentOrigin === "https://talenthub.intelegain.com"
        ? ["HR", "Senior Management"]
        : ["HR", "Senior Management", "Developer", "Management", "QA"];

    loginService
      .getUserDetails(accessToken)
      .then((response) => {
        // Check if the user's department is allowed
        if (allowedDepartments.includes(response.data.department)) {
          localStorage.setItem("user", JSON.stringify(response.data));
          if (getToken(navigate)) {
            // Navigate to the appropriate page if token retrieval is successful
          }
        } else {
          handleLogout();
        }
      })
      .catch((err) => {
        console.error("Error fetching user details:", err);
      });
  };

  const handleLogout = () => {
    // instance
    //   .logoutPopup() // Use logoutPopup for better control over navigation
    //   .then(() => {
    // Clear user data
    Cookies.remove("token");
    localStorage.clear();
    sessionStorage.clear();
    navigate("/accessdenied");
    // })
    // .catch((error) => {
    //   console.error("Error during logout", error);
    // });
  };

  return (
    <div className="app-container login-background">
      <div className="login-form">
        <div>
          <div className="title">
            <AgoraLogo width={200} height={200} />
          </div>
          <div className="text-center mb-3">
            <button
              type="submit"
              onClick={handleLogin}
              className="btn btn-primary agora-primary-btn login-btn btn-lg "
            >
              {isLoading ? (
                <ThreeDotLoading />
              ) : (
                <>
                  <span className="me-2">Log in</span>
                  <img src={LoginIcon} alt="Login Icon" />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SsoLogin;
