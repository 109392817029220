import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./css/main.scss";
import { Spin } from "antd";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginPage from "./components/login/LoginPage";
import "bootstrap/dist/css/bootstrap.min.css";
import SsoLogin from "./components/login/SsoLogin";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./utils/msalConfig";

const root = ReactDOM.createRoot(document.getElementById("root"));
const msalInstance = new PublicClientApplication(msalConfig);
const App = lazy(() => import("./App"));

root.render(
  <MsalProvider instance={msalInstance}>
    <RouterProvider
      router={createBrowserRouter([
        {
          path: "/login",
          element: (
            <Suspense
              fallback={
                <Spin style={{ minHeight: "100vh" }} size="large">
                  <div className="appLoader" />
                </Spin>
              }
            >
              {/* <LoginPage /> */}
              <SsoLogin />
            </Suspense>
          ),
        },
        {
          path: "*",
          element: (
            <Suspense
              fallback={
                <Spin style={{ minHeight: "100vh" }} size="large">
                  <div className="appLoader" />
                </Spin>
              }
            >
              <App />
            </Suspense>
          ),
        },
      ])}
    ></RouterProvider>
  </MsalProvider>
);
