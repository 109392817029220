import { LogLevel } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation.
 */

const LOCAL_URL = "http://localhost:3000";
const DEV_URL = "https://qatalenthub.dweb.in";
const PROD_URL = "https://talenthub.intelegain.com";

const currentOrigin = window.location.origin;
const RESOURCE_URL =
  currentOrigin === "https://talenthub.intelegain.com" ? PROD_URL : DEV_URL;

export const msalConfig = {
  auth: {
    clientId: "de7ecd24-ba31-48c2-ac35-84581e693a9d",
    authority:
      "https://login.microsoftonline.com/924d9ad1-25b5-46e1-beb4-612c790f213b",
    redirectUri:
      process.env.NODE_ENV === "production" ? RESOURCE_URL : LOCAL_URL,
    postLogoutRedirectUri:
      process.env.NODE_ENV === "production"
        ? RESOURCE_URL + "/login"
        : LOCAL_URL + "/login",
    // navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage", // "sessionStorage" or"localStorage"
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
    allowNativeBroker: false,
  },
};

export const loginRequest = {
  scopes: ["User.Read", "User.Read.All"],
};
